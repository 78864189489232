import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, catchError, map, timeout } from 'rxjs';
import { environment } from '@env/environment';
import {
  ApiClientConfig,
  ModeratorRole,
  ModeratorRoleResponse,
} from 'src/app/lib/auth/api.user.model';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(public readonly http: HttpClient) {}

  getModeratorRole(): Observable<ModeratorRole[]> {
    const token = this.getToken();
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
    return this.http
      .get<ModeratorRoleResponse>(`${environment.baseURL + '/user'}/admin/roles/module`, {
        headers,
      })
      .pipe(
        // timeout(environment.API_TIMEOUT),
        map((res) => {
          return res.data;
        }),
        catchError((error) => {
          throw error.error;
        })
      );
  }

  getToken(): string | null {
    const auth = localStorage.getItem(environment.TOKEN_KEY);
    const authValue = auth ?? '';
    if (authValue !== '') {
      try {
        return this.verifyStringJson(authValue) ? JSON.parse(authValue).accessToken : authValue; // Parse the JSON string to an object
      } catch (error) {
        console.error('Error parsing authentication token:', error);
        return null;
      }
    } else {
      return null;
    }
  }

  verifyStringJson(stringJson: string): boolean {
    try {
      JSON.parse(stringJson);
      return true;
    } catch (error) {
      return false;
    }
  }
}
