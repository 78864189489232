<ng-container *ngIf="stateView === 'INIT'; else appContent">
  <div class="loading-container">
    <div class="loading-bar"></div>
    <!-- <p class="loading-text">{{ 'common.loading.checking_permission' | translate }}...</p> -->
  </div>
</ng-container>

<ng-template #appContent>
  <router-outlet *ngIf="stateView === 'APP'"></router-outlet>
</ng-template>
<app-confirmation></app-confirmation>
