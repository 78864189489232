export const environment = {
  production: false,
  APP_NAME: 'Soctrip banner',
  COMPANY_NAME: 'TMA Technology Group',
  TOKEN: 'token_admin',
  AUTH_STATUS: 'auth_status_admin',
  USER_PROFILE: 'user_profile_admin',
  baseURL: 'https://api-dev-tmtrav.tma-swerp.com',
  TOKEN_KEY: 'accessToken',
  REFRESH_TOKEN_KEY: 'refresh_token',
  USER_ROLE_KEY: 'user_role',
  REFRESH_TOKEN_EXPIRY_KEY: 'refresh_token_expiry_date',
  ACCESS_TOKEN_EXPIRY_KEY: 'access_token_expiry_date',
  BASE_API_URL: 'https://api-dev-tmtrav.tma-swerp.com',
  ADMIN_APP_URL: 'https://admin-dev.tma-swerp.com',
  TRAVEL_ASSIST_URL: 'https://tmtrav-travel-dev.tma-swerp.com',
  BOOKING_URL: 'https://tmtrav-booking-dev.tma-swerp.com',
  ECOMMERCE_FOOD_URL: 'https://tmtrav-food-dev.tma-swerp.com',
  ECOMMERCE_URL: 'https://tmtrav-ecommerce-dev.tma-swerp.com',
  CARRENTAL_URL: 'https://tmtrav-car-rental-dev.tma-swerp.com',
  FLIGHT_URL: 'https://tmtrav-flight-dev.tma-swerp.com',
  API_TIMEOUT: 10000,
};
