import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, Router, RouterOutlet, UrlSegment } from '@angular/router';
import { FooterComponent } from '@layout/footer/footer.component';
import { HeaderComponent } from '@layout/header/header.component';
import { AuthService } from './services/auth.service';
import { TokenStorageService } from './services/token-storage.service';
import { UserService } from 'src/app/core/services/user/user.service';
import { PermissionService } from './core/services/permission/permission.service';
import { ButtonModule } from 'primeng/button';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmationService } from 'primeng/api';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { environment } from '@env/environment';
import { ConfirmationComponent } from '@shared/components/confirmation/confirmation.component';
import { ConfirmationDialogService } from '@shared/services/confirmation-dialog.service';
import { AvatarService } from '@shared/services/avatar.service';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { Language } from './config/language.config';

export const STATE_RENDER_APP = {
  INIT: 'INIT',
  IFRAME: 'IFRAME',
  APP: 'APP',
};
@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    /* primeng */
    ButtonModule,
    /* @angular */
    RouterOutlet,
    /* @components */
    FooterComponent,
    HeaderComponent,
    ConfirmDialogModule,
    ConfirmationComponent,
    TranslateModule,
    CommonModule,
  ],
  styleUrls: ['./app.component.scss'],
  templateUrl: './app.component.html',
  providers: [ConfirmationService, AvatarService],
})
export class AppComponent {
  loginForm = {
    phone_or_email: 'lhphu@tma.com.vn',
    password: 'Soctrip@123',
    area_code: '',
    device: '',
  };
  userRole: string[] = [];
  adminHubApp: string = environment.ADMIN_APP_URL;
  adminHubAppConnectUrl: string = environment.ADMIN_APP_URL + '/app-connect';
  adminHubAppSafeUrl: SafeResourceUrl;
  public sanitizer: DomSanitizer;
  stateView: string = STATE_RENDER_APP.INIT;

  constructor(
    private confirmationDialogService: ConfirmationDialogService,
    private authService: AuthService,
    private userService: UserService,
    private tokenStorageService: TokenStorageService,
    private permissionService: PermissionService,
    private avatarService: AvatarService,
    private translate: TranslateService
  ) {
    // this.login();
    window.addEventListener('message', this.receiveMessage);
    this.connectPMServer();
    const profile = JSON.parse(localStorage.getItem(environment.USER_PROFILE)!);
    this.getAvatar(profile?.avatar_thumbnail_url);
    const lang = this.getLangFromUrl();
    if (lang && Language.supported.includes(lang.toLowerCase())) {
      this.translate.setDefaultLang(lang);
    } else this.translate.setDefaultLang('en');
  }

  getLangFromUrl(): string | null {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get('lang');
  }
  connectPMServer() {
    const ifr = document.createElement('iframe');
    ifr.classList.add('hidden');
    document.body.appendChild(ifr);
    ifr.src = this.adminHubAppConnectUrl;
    const connect = () => {
      ifr.contentWindow?.postMessage({ type: 'connect' }, '*');
    };
    ifr.onload = connect;
  }

  @HostListener('window:message', ['$event'])
  async receiveMessage(event) {
    if (event.data.type === 'authorized' && event.origin === this.adminHubApp) {
      this.adminHubAppConnectUrl = '';
      if (!event.data || !event.data.token) {
        window.location.href = `${this.adminHubApp}/login?r=${window.location.origin}`;
        return;
      }
      this.tokenStorageService.saveToken(event.data.token.accessToken);
      const accessTokenData = event.data?.token?.accessToken;
      if (accessTokenData) {
        const hasPermission = await this.permissionService.checkPermission1(accessTokenData);

        if (hasPermission) {
          this.stateView = STATE_RENDER_APP.APP;
          localStorage.setItem(environment.USER_PROFILE, event.data.profile);
          const profile = JSON.parse(event.data.profile);
          this.getAvatar(profile?.avatar_thumbnail_url);
          this.userService.saveFrofile(JSON.stringify(event.data.profile));
          localStorage.setItem(environment.AUTH_STATUS, JSON.stringify({ isLoggedIn: true }));
        } else {
          this.navigateToLogin();
        }
      } else {
        this.navigateToLogin();
      }
    } else if (event.data.type === 'unauthorized') {
      this.navigateToLogin();
    }
  }
  getAvatar(avatarThumbnail: string) {
    let avatarUrl = environment.baseURL;

    if (avatarThumbnail && !avatarThumbnail?.includes('/')) {
      avatarUrl += `/storage/files/web/${avatarThumbnail}.webp`;
    } else {
      avatarUrl += '/media/libraries/default/UserAvatar.jpg';
    }
    this.avatarService.setData(avatarUrl);
  }
  setTheme(theme: string) {
    document.documentElement.className = theme;
  }

  login() {
    this.authService.login(this.loginForm).subscribe((res: any) => {
      this.tokenStorageService.saveToken(res.data.access_token);
      this.tokenStorageService.saveRefreshToken(res.data.refresh_token);
      this.tokenStorageService.saveAccessTokenExpiry(res.data.access_token_expiry_date);
      this.tokenStorageService.saveRefreshTokenExpiry(res.data.refresh_token_expiry_date);

      this.userService.getUserInfo(res.data.user.id).subscribe((res: any) => {
        this.userRole = res.data.roles;
        this.userService.saveUserInfoToLocalStorage(this.userRole);
      });
      this.permissionService.userRoles.next(this.userRole);
    });
  }

  logout() {
    this.tokenStorageService.clearTokenLocalStorage();
    this.userService.clearUserLocalStorage();
    this.permissionService.userRoles.next(['']);
  }

  onClose() {
    this.confirmationDialogService.clearConfirmationData();
  }

  navigateToLogin() {
    localStorage.clear();
    window.location.href = `${environment.ADMIN_APP_URL}/login`;
  }
}
